import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'

const icons = [
    { img:'/images/icons/facebook.png', path: 'https://www.facebook.com/people/TopLive/100075250351854/'},
    { img:'/images/icons/twitter.svg', path: 'https://twitter.com/toplive0'},
]

export default function Footer() {
    return (
        <footer className="Footer">
            <span className="footer-logo">
                <img src="/images/logo.png" alt="logo" />
            </span>
            <span className="footer-ready">Ready to Start?</span>
            <span className="footer-appstore">
                <a href="https://apps.apple.com/US/app/id1598551382" rel="noreferrer" target="_blank" >
                    <img src="/images/appstore_badge.png" alt="appstore_badge" />
                </a>
                <a href="/" rel="noreferrer" target="_blank" >
                    <img className="badge" src="/images/comming.png" alt="soon" />
                    <img className="store-badge" src="/images/google-play-badge.png" width={'247'} alt="play-market_badge" />
                </a>
            </span>
            <span className="footer-foolow">Follow Us</span>
            <div className="footer__social-icons">
                {
                    icons.map(({ img, path }, idx) => (
                        <a href={path} rel="noreferrer" target="_blank" key={idx}><img src={img} width={'24'} height={'24'} alt="icon"/></a>
                    ))
                }
            </div>
            <div className="footer-terms">
                <Link to='/terms'>
                    Terms
                </Link>
                <Link to='/privacy'>
                    Privacy
                </Link>
                <Link to='/contact'>
                    Contact Us
                </Link>
            </div>
            <p className="footer-copyright">Copyright © {new Date().getFullYear()}. Aligosta Limited. All Rights Reserved.</p>
        </footer>
    )
}
